import React from 'react';
import CarvedPumpkin from './BugsyPumpkinCarvingPattern.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={CarvedPumpkin} className="carved-pumpkin" alt="carved-pumpkin" />
        <a
          className="App-link"
          href="https://www.pumpkinlady.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Pumpkin carving Templates
        </a>
      </header>
    </div>
  );
}

export default App;
